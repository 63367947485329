<template>
  <div>
    <div class="inline">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thêm loại khách hàng'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="row">
                <b-form-group class="col-4">
                  <template>
                    <label>Tên:</label>
                  </template>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="customerType.name"
                    :state="validateState('name')"
                    required
                    placeholder="Nhập tên loại khách hàng"
                    class="mb-3"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    Vui lòng nhập tên loại khách hàng
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group class="col-4">
                  <template>
                    <label>Số tiền cần tiêu dùng:</label>
                    <span class="text-danger">*</span>
                  </template>

                  <b-input-group>
                    <b-form-input
                      class="input-style text-right"
                      size="sm"
                      type="text"
                      v-model="customerType.upgradeCost"
                      :state="validateState('upgradeCost')"
                      v-mask="currency"
                      placeholder="Số tiền"
                    ></b-form-input>

                    <b-input-group-append>
                      <span
                        class="input-group-text p-2 pl-3 pr-3"
                        style="color: #ffffff; background: #2ba0cb"
                        id="inputGroup-sizing-sm"
                        >VND</span
                      >
                    </b-input-group-append>
                    <b-form-invalid-feedback id="input-live-feedback">
                      Vui lòng nhập số tiền cần tiêu dùng
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group class="col-4">
                  <template>
                    <label>Trạng thái:</label>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    size="sm"
                    required
                    v-model="customerType.status"
                    :options="listStatus"
                    :state="validateState('status')"
                    class="select-style"
                    value-field="id"
                    text-field="name"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Vui lòng chọn trạng thái</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <template>
                      <label>Mô tả:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-textarea
                      size="sm"
                      v-model="customerType.description"
                      :placeholder="'Thêm mô tả'"
                      :rows="6"
                      :max-rows="8"
                      :state="validateState('description')"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Vui lòng nhập mô tả</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </v-form>
            <div class="separator separator-dashed my-5"></div>

            <b-row class="mb-2">
              <b-col cols="12">
                <div class="d-flex align-items-center">
                  <h5 class="m-0">Cấu hình:</h5>
                  <b-button
                    class="ml-1"
                    style="fontweight: 600"
                    variant="primary"
                    size="sm"
                    @click="insertField"
                  >
                    <i style="font-size: 1rem" class="flaticon2-add-1 pr-0"></i>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <div>
                  <b-table
                    class="table-bordered table-hover col-md-12"
                    :fields="fields"
                    :items="settings"
                    v-if="settings.length"
                  >
                    <template v-slot:cell(typeItem)="row">
                      <treeselect
                        :options="listField"
                        :multiple="false"
                        size="sm"
                        placeholder="Chọn loại"
                        noResultsText="Không có kết quả"
                        :match-keys="['label']"
                        openDirection="bottom"
                        :clearable="false"
                        v-model="row.item.typeItem"
                      >
                        <label
                          slot="option-label"
                          slot-scope="{ node, labelClassName }"
                          :class="labelClassName"
                        >
                          <span :title="node.label"> {{ node.label }}</span>
                        </label>
                      </treeselect>
                    </template>

                    <template v-slot:cell(referentId)="row">
                      <Autosuggest
                        v-if="row.item.typeItem === 'category'"
                        :model="row.item.selectedCate"
                        :suggestions="filteredCateOptions"
                        placeholder="danh mục sản phẩm"
                        @select="(option) => onSelectedCate(option, row.index)"
                        @change="
                          (text) => {
                            onInputCateChange(text);
                          }
                        "
                        suggestionName="suggestionName"
                      />
                    </template>

                    <template v-slot:cell(typeDiscount)="row">
                      <treeselect
                        :options="listTypeDiscount"
                        :multiple="false"
                        size="sm"
                        placeholder="Chọn loại"
                        noResultsText="Không có kết quả"
                        :match-keys="['label']"
                        openDirection="bottom"
                        :clearable="false"
                        v-model="row.item.typeDiscount"
                      >
                        <label
                          slot="option-label"
                          slot-scope="{ node, labelClassName }"
                          :class="labelClassName"
                        >
                          <span :title="node.label"> {{ node.label }}</span>
                        </label>
                      </treeselect>
                    </template>

                    <template v-slot:cell(value)="row">
                      <b-input
                        type="text"
                        size="sm"
                        class="input-style"
                        v-model="row.item.value"
                        v-mask="currency"
                        @change="() => validateValueDiscount(row.index)"
                      ></b-input>
                    </template>
                    <template v-slot:cell(maxValue)="row">
                      <b-input
                        type="text"
                        size="sm"
                        class="input-style"
                        v-model="row.item.maxValue"
                        v-mask="currency"
                        @change="() => validateValueDiscount(row.index)"
                      ></b-input>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <v-icon
                        small
                        class="text-danger"
                        @click="
                          showDeleteAlert(
                            'Xóa cấu hình đang được áp dụng !',
                            'Bạn có chắc muốn xóa cấu hình đang được áp dụng ?',
                            row.item,
                            fetchDeleteSetting,
                          )
                        "
                        v-b-tooltip
                        title="Xóa"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </b-table>
                </div>
              </b-col>
              <b-col cols="6" md="4"></b-col>
            </b-row>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="createCustomer"
              >Lưu</b-button
            >
            <router-link :to="'/customer-type'">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.gender-appellation {
  display: flex;
  gap: 4px;
}

.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
// import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import {
  CUSTOMER_TYPE,
  CUSTOMER_GENDER,
  CUSTOMER_APPELLATION,
} from '@/utils/constants';
// Import datePicker component
// import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
// import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import {
  xoa_dau,
  makeToastSuccess,
  makeToastFaile,
} from '../../../utils/common';
import { currencyMask, unMaskPrice } from '../../../utils/common';
import decounce from 'debounce';
import { v4 } from 'uuid';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { showDeleteAlert } from '@/utils/sweet-alert2';

export default {
  mixins: [validationMixin],
  data() {
    return {
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      settings: [],
      fields: [
        {
          key: 'typeItem',
          label: 'Loại',
          thStyle: { textAlign: 'center', width: '20%' },
        },
        {
          key: 'referentId',
          label: 'Mã',
          thStyle: { textAlign: 'center', width: '20%' },
        },
        {
          key: 'typeDiscount',
          label: 'Loại chiết khấu',
          thStyle: { textAlign: 'center', width: '15%' },
        },
        {
          key: 'value',
          label: 'Giá trị (% / VND)',
          thStyle: { textAlign: 'center', width: '20%' },
        },
        {
          key: 'maxValue',
          label: 'Tổng chiết khấu (VND)',
          thStyle: { textAlign: 'center', width: '20%' },
        },

        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      listField: [
        { id: '', label: 'Chọn loại' },
        { id: 'category', label: 'Nhóm sản phẩm' },
      ],
      listTypeDiscount: [
        { id: 'percent', label: 'Chiết khấu (%)' },
        { id: 'amount', label: 'Tiền mặt ($)' },
      ],
      currency: currencyMask,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      customerType: {
        name: null,
        upgradeCost: null,
        description: null,
        status: null,
      },
      valid: true,
      form: {
        name: '',
        phone: '',
        selectedGender: null,
        appellation: null,
      },
      listStatus: [
        { id: null, name: 'Chọn trạng thái' },
        { id: false, name: 'Tắt' },
        { id: true, name: 'Bật' },
      ],
      isNew: true,
      radios: 'male',
      selectedType: null,
      listType: CUSTOMER_TYPE,
      note: '',
      birthday: '',
      address: '',
      selectedCity: null,
      listCity: [],
      selectedDistrict: null,
      listDistrict: [],
      selectWard: null,
      listWard: [],
      listGender: CUSTOMER_GENDER,
      listAppellation: CUSTOMER_APPELLATION,
      selectedEmployee: null,
      listEmployee: [],
      lastName: '',
      filteredOptionEmployee: [],
      optionEmployee: [],
      searchEmployee: '',
      email: '',
      showErrorGender: false,
    };
  },
  validations: {
    customerType: {
      status: { required },
      upgradeCost: { required },
      name: { required },
      description: { required },
    },
  },
  components: {
    KTCodePreview,
    Treeselect,
    // datePicker,
    Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách loại khách hàng', route: '/customer-type' },
      { title: 'Thêm loại khách hàng' },
    ]);
  },
  created() {
    this.fetchEmployee();
    this.fetchCity();
  },
  methods: {
    showDeleteAlert,
    insertField() {
      const item = {
        id: this.newId(),
        selectedCate: '',
        typeDiscount: 'percent',
        typeItem: 'category',
        value: 0,
        maxValue: 0,
        referentId: [],
      };
      this.settings.push(item);
    },
    newId() {
      return v4();
    },
    fetchCategory: function() {
      let param = {
        page: 1,
        limit: 9999999,
        name: this.selectedCate,
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        this.numberOfPage = data.data.total_page;
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '-' + element.name;
          } else if (element.level === 2) {
            name = '--' + element.name;
          } else if (element.level === 3) {
            name = '---' + element.name;
          } else if (element.level === 4) {
            name = '----' + element.name;
          } else if (element.level === 5) {
            name = '-----' + element.name;
          } else if (element.level === 6) {
            name = '------' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [...this.cateOptions[0].data];
      });
    },
    fetchCategoryId: async function(id) {
      if (!id) return;
      const { data } = await ApiService.query(`category/${id}`);
      return data.data;
    },
    fetchDeleteSetting(itemSelected) {
      this.settings = this.settings.filter(
        (item) => item.id !== itemSelected.id,
      );

      makeToastSuccess('Xóa cấu hình thành công');
    },

    onSelectedCate(option, index) {
      this.settings[index]['referentId'] = [option.item.id];
      // this.settings[index]['referentId'].push(option.item.id);
    },
    onInputCateChange(text) {
      this.selectedCate = text;
      this.debounceInputSearchCate();
    },
    validateValueDiscount(index) {
      if (
        this.settings[index]['typeDiscount'] === 'percent' &&
        unMaskPrice(this.settings[index]['value']) > 100
      ) {
        makeToastFaile('Chiết khấu đang vượt quá 100%');
        this.isValidateValueDiscount = true;
        return;
      }
      this.isValidateValueDiscount = false;
    },
    validateReferentId(index) {
      // console.log(this.settings[index]['referentId']);
      if (!this.settings[index]['referentId'].length) {
        this.isValidateValueReferendId = true;
        return;
      }
      this.isValidateValueReferendId = false;

      return {
        _value: unMaskPrice(this.settings[index]['value']) || 0,
        _maxValue: unMaskPrice(this.settings[index]['maxValue']) || 0,
      };
    },
    debounceInputSearchCate: decounce(function() {
      this.fetchCategory();
    }, 500),
    format(value) {
      this.birthday = value;
      return value;
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.customerType[name];
        return $dirty ? !$error : null;
      }
    },
    createCustomer: async function() {
      this.isNew = false;
      this.$v.customerType.$touch();
      if (this.$v.customerType.$anyError) {
        return;
      }
      this.customerType['upgradeCost'] = unMaskPrice(
        this.customerType['upgradeCost'] || 0,
      );
      const data = {
        name: this.customerType.name,
        description: this.customerType.description,
        upgradeCost: this.customerType.upgradeCost,
        status: this.customerType.status,
      };

      if (this.settings.length) {
        const _setting = [...this.settings];
        const setting = _setting.map((item, index) => {
          this.validateValueDiscount(index);
          const { _value, _maxValue } = this.validateReferentId(index);
          item['value'] = _value;
          item['maxValue'] = _maxValue;
          if (typeof item['id'] === 'string') {
            delete item['id'];
          }
          return item;
        });
        if (this.isValidateValueDiscount) {
          makeToastFaile(
            'Dữ liệu chiết khấu không đúng, vui lòng kiểm tra lại !',
          );
          return;
        }

        if (this.isValidateValueReferendId) {
          makeToastFaile('Vui lòng chọn danh mục chiết khấu !');
          return;
        }
        data['settings'] = setting;
      }

      if (this.isValidateValueDiscount) {
        makeToastFaile(
          'Dữ liệu chiết khấu không đúng, vui lòng kiểm tra lại !',
        );
        return;
      }

      ApiService.post('customer/setting-type', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.$router.push({
              path: '/customer-type',
            });
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch((error) => {
          console.log('error: ', error);
          this.makeToastFaile('Thêm loại khách hàng bị lỗi!!!!');
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchEmployee: async function() {
      this.optionEmployee = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          let nameNoneSign = xoa_dau(element.name);
          let store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee.push(store);
        });
        this.filteredOptionEmployee = [...this.optionEmployee];
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      this.selectedDistrict = null;
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
      });
    },
    onContext(ctx) {
      if (ctx.selectedYMD == '') {
        this.birthday = '';
      } else {
        this.birthday = ctx.selectedYMD;
      }
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item.name;
      this.selectedEmployee = option.item.id;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [...filteredData];
    },
  },
};
</script>
